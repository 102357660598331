module.exports = [{
      plugin: require('/Users/pedroigor/Documents/devsquad/barlavento/site/node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/pedroigor/Documents/devsquad/barlavento/site/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/pedroigor/Documents/devsquad/barlavento/site/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-3D3MDLV5WR"],"pluginConfig":{"head":true}},
    },{
      plugin: require('/Users/pedroigor/Documents/devsquad/barlavento/site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
